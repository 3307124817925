import React from 'react';
import { graphql } from 'gatsby';
import Container from '../../../primitives/grid/container';
import Row from '../../../primitives/grid/row';
import Col from '../../../primitives/grid/col';
import { borderRadius, breakpoints } from '../../../primitives/tokens';
import Typography from '../../../primitives/typography';
import { colors } from '../../../primitives/colors';
import AspectRatio from '../../../primitives/aspect-ratio';
import FadeTransition from '../../../animations/transitions/fade-transition';
import VideoPopover from '../../../primitives/video-popover';
import DatoAsset from '../../../primitives/asset';
import MediaItem from '../../../primitives/media-item';
import CustomStructuredTextRenderer from '../../custom-structured-text-renderer';

export type SingleQuoteShowcaseProps = Queries.SingleQuoteShowcasePropsFragment;

const SingleQuoteShowcase = ({ quote: item }): JSX.Element => {
    const items = [item];
    const slideIndex = 0;
    return (
        <Container css={{
            marginTop: '96px',
            marginBottom: '120px',
            [breakpoints.mb]: {
                marginTop: '45px',
                marginBottom: '75px',
            },
        }}
        >
            <Row>
                <Col breakpoints={{ dt: { span: 7 }, tb: { span: 6 } }}>

                    <div css={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        height: '100%',
                        marginTop: '62px',
                        [breakpoints.mb]: {
                            alignItems: 'center',
                        },
                    }}
                    >
                        <div />
                        <div>
                            <div css={{
                                textAlign: 'left',
                                [breakpoints.mb]: {
                                    textAlign: 'center',
                                },
                            }}
                            >
                                <Typography
                                    fontSize={{
                                        dt: 3234,
                                        tb: 2429,
                                        mb: 1822,
                                    }}
                                    css={{
                                        lineHeight: `1.3!important`
                                    }}
                                    fontWeight="light"
                                    override
                                >
                                    <div>“</div>
                                    <CustomStructuredTextRenderer
                                        data={item.quote}
                                    />
                                </Typography>

                                <Typography
                                    css={{
                                        paddingTop: '40px',
                                        [breakpoints.mb]: {
                                            paddingTop: '36px',
                                        },
                                    }}
                                    fontSize={{
                                        dt: 1822,
                                    }}
                                    override
                                >
                                    <span>{item.attribution}</span>
                                    <br css={{
                                        display: 'none',
                                        [breakpoints.mb]: {
                                            display: 'unset',
                                        },
                                    }}
                                    />
                                    <span css={{ marginLeft: '12px', opacity: 0.5 }}>{item.role}</span>
                                </Typography>
                            </div>
                        </div>
                        <div css={{
                            paddingTop: '65px',
                            [breakpoints.mb]: {
                                paddingTop: '22px',
                            },
                        }}
                        />
                    </div>
                </Col>
                <Col breakpoints={{ dt: { span: 1}, tb: { span: 1, hidden: true } }} />
                <Col breakpoints={{ dt: { span: 4 }, tb: { span: 6 }, mb: { span: 4, hidden: true } }}>
                    <div css={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                    >
                        <div
                            css={{
                                borderRadius: borderRadius.large,
                                boxShadow: '0 17px 44px rgba(0, 0, 0, 0.27)',
                                backgroundColor: colors.primaryDarkPurple,
                            }}
                        >
                            <AspectRatio breakpoints={{
                                dt: { height: 318, width: 318 },
                            }}
                            >
                                <div css={{ display: 'flex', height: '100%', width: '100%' }}>
                                    <FadeTransition shouldChange={slideIndex} css={{ width: '100%' }}>
                                        {items && items[slideIndex] && (
                                            <div
                                                css={{
                                                    width: '100%',
                                                }}
                                            >
                                                <VideoPopover video={items[slideIndex].mediaItem?.[0]?.media} showPlayButton>
                                                    {items[slideIndex]?.thumbnail ? (
                                                        <DatoAsset
                                                            css={{
                                                                userSelect: 'none',
                                                                borderRadius: borderRadius.large,
                                                                overflow: 'hidden',
                                                                transform: 'translateZ(0px)',
                                                                height: '100%',
                                                                width: '100%',
                                                            }}
                                                            asset={items[slideIndex].thumbnail}
                                                            isStatic
                                                        />
                                                    ) : (
                                                        <MediaItem
                                                            css={{
                                                                userSelect: 'none',
                                                                borderRadius: borderRadius.large,
                                                                overflow: 'hidden',
                                                                transform: 'translateZ(0px)',
                                                                height: '100%',
                                                                width: '100%',
                                                            }}
                                                            isStatic
                                                            item={items[slideIndex].mediaItem}
                                                            onPlayStateChange={(state) => {
                                                            }}
                                                        />
                                                    )}
                                                </VideoPopover>
                                            </div>
                                        )}
                                    </FadeTransition>
                                </div>

                            </AspectRatio>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default SingleQuoteShowcase;

export const query = graphql`
    fragment SingleQuoteShowcaseProps on DatoCmsSingleQuoteShowcase {
        __typename
        quote {
            position
            quote {
                blocks
                links
                value
            }
            attribution
            role
            mediaItem {
                ...MediaItemProps
            }
            thumbnail {
                ...DatoCmsInternalAsset
            }
        }
    }
`;
